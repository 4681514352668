<template>
  <div :class="['expander', { 'is-expanded': isExpanded }]">
    <div class="expander-header">
      <slot name="header"/>
    </div>

    <div class="sr-only">
      <slot/>
    </div>

    <transition-expand>
      <div class="expander-body" v-if="isExpanded" aria-hidden="true">
        <slot/>
      </div>
    </transition-expand>

    <div class="expander-action">
      <a class="expander-toggle" href="#" @click.prevent="toggle"><b-icon icon="plus" scale="1.5"/></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expander',
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isExpanded: this.$props.expanded
    }
  },
  methods: {
    toggle () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style lang="scss">
$expander-padding-y: $component-padding-y * 0.5 !default;
$expander-padding-x: 0 !default;
$expander-border: $border-width $border-style $border-color !default;

$expander-toggle-transition-in: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 500ms !default;
$expander-toggle-transition-out: transform cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

.expander {
  padding: $expander-padding-y $expander-padding-x;
  border-top: $expander-border;
  border-bottom: $expander-border;

  .expander-action {
    display: flex;
    justify-content: flex-end;
    margin-top: -1em;

    .expander-toggle {
      transition: $expander-toggle-transition-out;
    }
  }

  &.is-expanded {
    .expander-action {
      .expander-toggle {
        transform: rotate(-135deg);
        transition: $expander-toggle-transition-in;
      }
    }
  }

  + .expander {
    border-top: 0 none;
  }
}
</style>
